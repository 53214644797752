import { Row, Col, Select } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { useEffect } from "react";
import i18n from "i18next";
import {
  FooterSection,
  Title,
  Extra,
  Para,
  Large,
  Chat,
  Label,
  Slogan
} from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
  useEffect(() => {
    const handleLanguageChange = () => {
      // Force re-render when language changes
      // This is needed because some components might not update automatically
      window.dispatchEvent(new Event('resize'));
    };

    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
    
    // Update URL without page reload if we're on a tally page
    if (window.location.pathname.includes('/tally/')) {
      const currentPath = window.location.pathname.split('/');
      currentPath[2] = language; // Update language segment
      const newPath = currentPath.join('/');
      window.history.pushState({}, '', newPath);
    }
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Title>{t("Contact")}</Title>
              <Large to="/">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:iapp605+tally@gmail.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <Large to={`/tally/${i18n.language}/privacy`}>{t("Privacy Policy")}</Large>
              <Large to={`/tally/${i18n.language}/support`}>{t("Support & Feedback")}</Large>
              <Large to={`/tally/${i18n.language}/terms`}>{t("Terms of Service")}</Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <Select
                value={i18n.language}
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: 'en', label: 'English' },
                  { value: 'zh', label: '简体中文' },
                  { value: 'zh-TW', label: '繁體中文' },
                ]}
              />
            </Col>
          </Row>         
        </Container>
      </FooterSection>
      
      <Extra>
        <Container border={true}>
          <Row
            justify="center"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <Slogan>Small numbers, big results</Slogan>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
